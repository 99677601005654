import React from "react"
import * as R from 'ramda'
import Helmet from "react-helmet"
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Layout from "../components/layout"
import SEO from "../components/seo"

const Cookies = ({data, location}) => { 
  return (
    <Layout noHero>
      <SEO
        sitename={R.path(['brandSettings', 'nimi'], data)}
        seoTitle="Cookies"
        url={location.href}
      />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <script id="CookieDeclaration" src="https://consent.cookiebot.com/8cd2c427-6675-49f7-a381-519d65c1430d/cd.js" type="text/javascript" async></script>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Cookies